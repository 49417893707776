import {
  apiClient,
  apiDeserializer,
  formRequestHeader,
  requestHeader,
} from "@/services/GenericService.js";

import { courseBadgeAPIUrls, dashboardBadges } from "@/urls.js";

export const getBadge = async (badgeId) => {
  const resp = await fetch(`/api/badges/${badgeId}/`);
  if (resp.status === 404) {
    console.log("No badge found");
  } else {
    const json = await resp.json();
    return json;
  }
};

export const createBadge = async (newBadge) => {
  const resp = await fetch("/api/badges/", {
    method: "POST",
    headers: requestHeader,
    credentials: "same-origin",
    body: newBadge,
  });
  if (!resp.ok) {
    alert("Could not create a new badge due to an unexpected problem");
  } else {
    const json = await resp.json();
    return json;
  }
};

export const deleteBadge = async (badgeId) => {
  let badge = await getBadge(badgeId);
  let name = badge.data.attributes.name;
  const resp = await fetch(`/api/badges/${badgeId}`, {
    method: "DELETE",
    headers: requestHeader,
    credentials: "same-origin",
    body: JSON.stringify({ badge: { id: badgeId } }),
  });
  if (!resp.ok) {
    alert("Could not delete badge due to an unexpected problem");
    return false;
  }
  return `Deleted ${name}`;
};

export const copyBadge = async (badgeId) => {
  let badge = await getBadge(badgeId);
  let name = badge.data.attributes.name;
  const resp = await fetch(`/api/badges/copy`, {
    method: "POST",
    headers: requestHeader,
    credentials: "same-origin",
    body: JSON.stringify({ badge: { id: badgeId } }),
  });
  if (!resp.ok) {
    alert("Could not copy badge due to an unexpected problem");
    return false;
  }
  return `Copied ${name}`;
};
export const updateBadge = async (updatedBadgeParams) => {
  let headers = requestHeader;

  if (updatedBadgeParams.formHeader) {
    headers = formRequestHeader;
  }

  const resp = await fetch(`/api/badges/${updatedBadgeParams.id}/`, {
    method: "PUT",
    headers: headers,
    credentials: "same-origin",
    body: updatedBadgeParams.body,
  });
  if (!resp.ok) {
    alert("Could not update badge due to an unexpected problem");
  }
};

export const createBadgeAttachments = async (badgeAttachmentDetails) => {
  const resp = await fetch(
    `/api/badges/${badgeAttachmentDetails.id}/file_uploads`,
    {
      method: "POST",
      headers: formRequestHeader,
      credentials: "same-origin",
      body: badgeAttachmentDetails.body,
    }
  );
  if (!resp.ok) {
    alert("Could not add badge attachments due to an unexpected problem");
  }
};

export const deleteBadgeFile = async (badgeFileId) => {
  const resp = await fetch(`/api/badge_files/${badgeFileId}`, {
    method: "DELETE",
    headers: requestHeader,
    credentials: "same-origin",
  });
  if (!resp.ok) {
    alert("Could not delete badge file due to an unexpected problem");
  }
};

export const getCourseBadges = async (courseId) => {
  const url = courseBadgeAPIUrls.list.stringify({ courseId });
  const { data } = await apiClient.get(url);
  return data;
};

export const getBadgeDetails = async (courseId, badgeId) => {
  const url = courseBadgeAPIUrls.details.stringify({ courseId, badgeId });
  const { data } = await apiClient.get(url);
  return data;
};

export const reorderCourseBadges = async (courseId, badges) => {
  const url = courseBadgeAPIUrls.reorder.stringify({ courseId });
  const response = await apiClient.post(url, { badge: badges });
  return response;
};

// uses current_user
export const getDashboardBadges = async (courseId) => {
  let url = dashboardBadges.stringify({ courseId });
  const { data } = await apiClient.get(url);
  return data.map(apiDeserializer);
};
