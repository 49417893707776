import { apiClient } from "@/services/GenericService.js";
import { apiResponseToDataDataItem } from "../helpers/apiRequestHelpers.js";

import { courseCreationUrl, getNavigationCountsUrl } from "@/urls.js";

export const getCourseCreationChecklist = async () => {
  const { data } = await apiClient.get(courseCreationUrl.stringify());
  return apiResponseToDataDataItem(data);
};

export const updateCourseCreationChecklist = async (item) => {
  const params = { course_creation: { [item.name]: item["done"] } };
  const { data } = await apiClient.put(courseCreationUrl.stringify(), params);
  return apiResponseToDataDataItem(data);
};

export const getNavigationCounts = async (courseId) => {
  const { data } = await apiClient.get(
    getNavigationCountsUrl.stringify({ courseId })
  );
  return data;
};
