<script setup>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useJobStore } from "@/pinia/jobs";
import { useNotificationStore } from "@/pinia/notifications.js";
import { getUrlParams } from "@/utilities/requests.js";
import { NOTIFICATION_VISIBILITY_TYPES } from "@/utilities/constants.js";
import BannerNotification from "./BannerNotification.vue";

const store = useStore();

const user = computed(() => store.state.user);
const course = computed(() => store.state.course);

const notificationStore = useNotificationStore();
const jobStore = useJobStore();

const visibileNotifications = computed(() =>
  notificationStore.notifications.filter(
    (notification) =>
      notification.visibility === NOTIFICATION_VISIBILITY_TYPES.visible
  )
);

onMounted(async () => {
  // Skip course-specific notifications for pages that are not scoped to a course (i.e. Dashboard)
  if (!course.value.courseId) return;

  const today = new Date();
  const createdAt = today.setDate(today.getDate() - 7);
  await jobStore.getJobs(user.value.id, course.value.courseId, {
    created_at_gt: createdAt,
  });

  // Check if a referring page wants the banner dismissed
  // Used if the URL the user is coming from is an injected HTML message
  const referrerNotifId = getUrlParams().get("dismiss_banner");
  const referrerNotif = jobStore.getJobById(referrerNotifId);
  if (referrerNotif) {
    notificationStore.updateNotification(
      referrerNotifId,
      course.value.courseId,
      {
        visible: NOTIFICATION_VISIBILITY_TYPES.hidden,
      }
    );
  }

  await jobStore.addJobNotifications();
});

const removeNotification = (notification) => {
  if (notification.worker) {
    notificationStore.updateNotification(
      notification.id,
      course.value.courseId,
      {
        visible: NOTIFICATION_VISIBILITY_TYPES.hidden,
      }
    );
  } else {
    notificationStore.removeNotification(notification);
  }
};
</script>

<template>
  <div class="banner-container">
    <banner-notification
      v-for="(notification, index) in visibileNotifications"
      :key="index"
      :notification="notification"
      @remove-notification="removeNotification(notification)"
    />
  </div>
</template>
