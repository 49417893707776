import Transform from "../utilities/transformAPIResponse";
import {
  apiDeserializer,
  apiSerializer,
  requestHeader,
} from "@/services/GenericService.js";
import {
  gradeSchemeElementDeleteAllAPIUrl,
  gradeSchemeElementListUpdateAPIUrl,
} from "@/urls.js";

export const gradeSchemeElementsModule = {
  namespaced: true,
  state: {
    currentGradeScheme: [],
    modifiedGradeScheme: [],
  },
  actions: {
    getGradeSchemeElements: async function ({ commit }) {
      const url = gradeSchemeElementListUpdateAPIUrl.stringify();
      const resp = await fetch(url);
      if (!resp.ok) {
        let failMessage = "Unable to successfully retrieve your Grade Scheme.";
        commit("addErrorAlertMessage", failMessage, { root: true });
        return;
      }
      const json = await resp.json();
      const gradeSchemeElements = Transform.apiResponseToData(json);
      commit("setGradeSchemeElements", gradeSchemeElements);
      commit("setModifiedGradeScheme", gradeSchemeElements);
    },
    updateGradeSchemeElements: async function ({ commit, dispatch }, payload) {
      let convertedCase = payload.updateArray.map(apiSerializer);
      const url = gradeSchemeElementListUpdateAPIUrl.stringify();
      const resp = await fetch(url, {
        method: "PUT",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify({
          grade_scheme_elements_attributes: convertedCase,
          deleted_ids: payload.deleted_ids,
        }),
      });
      if (!resp.ok) {
        let failMessage = "Unable to successfully update your Grade Scheme.";
        commit("addErrorAlertMessage", failMessage, { root: true });
      } else {
        let successMessage = "Successfully updated your Grade Scheme.";
        commit("addSuccessAlertMessage", successMessage, { root: true });
        dispatch("getGradeSchemeElements");
      }
    },
    deleteAllGradeSchemeElements: async function ({ commit }) {
      const url = gradeSchemeElementDeleteAllAPIUrl.stringify();
      const resp = await fetch(url, {
        method: "DELETE",
        headers: requestHeader,
        credentials: "same-origin",
      });
      if (!resp.ok) {
        let failMessage = "Unable to delete all Grade Scheme Elements.";
        commit("addErrorAlertMessage", failMessage, { root: true });
      } else {
        let successMessage = "Successfully deleted all Grade Scheme Elements.";
        commit("addSuccessAlertMessage", successMessage, { root: true });
        commit("setGradeSchemeElements", []);
        commit("setModifiedGradeScheme", []);
      }
    },
  },
  mutations: {
    setGradeSchemeElements(state, elements) {
      state.currentGradeScheme = elements.map(apiDeserializer);
    },
    setModifiedGradeScheme(state, elements) {
      state.modifiedGradeScheme = elements.map(apiDeserializer);
    },
  },
  getters: {
    gradeSchemeElements: (state) => {
      return state.currentGradeScheme;
    },
    modifiedGradeScheme: (state) => {
      return state.modifiedGradeScheme;
    },
    savedGSE: (state) => {
      return state.currentGradeScheme.some((gse) => gse.id);
    },
    highestGradeSchemeElement(state, getters) {
      return getters.gradeSchemeElements.length
        ? getters.descendingGradeSchemeElements[0]
        : undefined;
    },
    ascendingGradeSchemeElements(state, getters) {
      return getters.gradeSchemeElements.sort(
        (a, b) => a.lowestPoints - b.lowestPoints
      );
    },
    descendingGradeSchemeElements(state, getters) {
      return [...getters.gradeSchemeElements].sort(
        (a, b) => b.lowestPoints - a.lowestPoints
      );
    },
  },
};
