<template>
  <div v-if="showChecklist" class="course-creation-checklist side-nav-staff">
    <h3 class="course-creation-checklist__title">Course Creation</h3>
    <div class="course-creation-progress">
      <div
        class="course-creation-progress__fill"
        :style="{ width: progressCompleted }"
      ></div>
      <!--vary width by progress-->
    </div>
    <div class="course-creation-progress__title">{{ completionMessage }}</div>
    <ul
      class="course-creation-checklist__items"
      aria-label="Course Creation Steps"
    >
      <li
        class="course-creation-checklist__item sidenav__item"
        v-for="item in courseCreationProgress.checklist"
        :key="item.title"
      >
        <input
          :id="`${item.title.replace(' ', '_')}`"
          @change="updateCourseCreationItem(item)"
          type="checkbox"
          v-model="item.done"
        />
        <label>
          <a :href="item.url" class="course-creation-checklist__choice">
            {{ item.title }}
          </a>
        </label>
      </li>
    </ul>
  </div>

  <nav>
    <ul class="sidenav__group staff-sidenav">
      <li
        v-for="section in courseStaffNavItems"
        :key="section.name"
        class="sidenav__item sidenav__group-title"
      >
        <accordion-component
          accordion-content="bg-blue"
          :open-default="navOpenDefaults[section.name]"
          :nav-info="section.name"
          accordion-trigger-class="accordion-trigger__button--for-sidenav"
          accordion-trigger-button-class="accordion-trigger__button--after w-100"
        >
          <template #heading>
            <div class="sidenav__group-header">
              <i :class="`fa fa-fw fa-${section.sectionIcon}`"></i>
              {{ section.name }}
            </div>
          </template>
          <template #content>
            <ul class="sidenav__list">
              <template v-for="(navItem, index) in section.items" :key="index">
                <li
                  v-if="navItem.show"
                  :class="{ active: navItem.active }"
                  class="sidenav__item"
                >
                  <template v-if="navItem.active">
                    {{ navItem.text }}
                    <span
                      v-if="navItem.count > 0"
                      class="notification-badge staff-sidenav__notification-count"
                    >
                      {{ navItem.count }}
                    </span>
                  </template>
                  <template v-else>
                    <a
                      v-if="navItem.count > 0"
                      :href="navItem.url"
                      :tabindex="navOpenDefaults[section.name] ? 0 : -1"
                      class="sidenav__link"
                    >
                      <span class="staff-notification-badge__title">
                        {{ navItem.text }}</span
                      >
                      <span
                        class="notification-badge staff-sidenav__notification-count"
                      >
                        {{ navItem.count }}
                      </span>
                    </a>
                    <a
                      v-else
                      :href="navItem.url"
                      :tabindex="navOpenDefaults[section.name] ? 0 : -1"
                      class="sidenav__link"
                    >
                      {{ navItem.text }}
                    </a>
                  </template>
                </li>
              </template>
            </ul>
          </template>
        </accordion-component>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AccordionComponent from "./structure/AccordionComponent.vue";
export default {
  name: "SideNavStaff",
  props: {
    showChecklist: {
      type: Boolean,
      required: true,
    },
    courseStaffNavItems: {
      type: Object,
      required: true,
    },
  },
  components: {
    AccordionComponent,
  },
  computed: {
    ...mapState("navigation", ["courseCreationProgress", "navOpenDefaults"]),
    completionMessage() {
      let message = "";
      if (this.courseCreationProgress.checklist) {
        message = `${this.progress}/${this.courseCreationProgress.checklist.length} items complete!`;
      }
      return message;
    },
    progress() {
      return (
        this.courseCreationProgress.checklist?.filter(
          (item) => item.done == true
        ).length || 0
      );
    },
    progressCompleted() {
      let value = "0%";
      if (this.courseCreationProgress.checklist) {
        value = `${
          (this.progress / this.courseCreationProgress.checklist.length) * 100
        }%`;
      }
      return value;
    },
  },
  methods: {
    ...mapActions("navigation", ["updateCourseCreationItem"]),
  },
};
</script>
