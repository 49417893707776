import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import { transformPayloadToSnakeCase } from "@/utilities/transforms.js";

const csrfToken = document.head.querySelector("[name='csrf-token']").attributes
  .content.value;

const camelizeResponse = (data) => {
  try {
    return camelcaseKeys(JSON.parse(data), { deep: true });
  } catch {
    return data;
  }
};

const decamelizeRequest = (data) =>
  data === undefined ? undefined : transformPayloadToSnakeCase(data);

const apiClient = axios.create({
  headers: {
    common: {
      "X-CSRF-Token": csrfToken,
    },
  },
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 422;
  },
  transformRequest: [decamelizeRequest, ...axios.defaults.transformRequest],
  transformResponse: [camelizeResponse],
});

const formDataAPIClient = axios.create({
  headers: {
    "X-CSRF-Token": csrfToken,
    "Content-Type": "multipart/form-data",
  },
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 422;
  },
  transformResponse: [camelizeResponse],
});

const transformedFormDataAPIClient = axios.create({
  headers: {
    "X-CSRF-Token": csrfToken,
    "Content-Type": "multipart/form-data",
  },
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 422;
  },
  transformRequest: [decamelizeRequest, ...axios.defaults.transformRequest],
  transformResponse: [camelizeResponse],
});

export { apiClient, formDataAPIClient, transformedFormDataAPIClient };
