const DEFAULT_KEYS_TO_INCLUDE = [
  "courses",
  "users",
  "team_memberships",
  "badges",
  "earned_badges",
  "assignments",
  "course_memberships",
  "staff",
  "payments",
  "subscriptions",
  "billing_schemes",
  "submissions",
];
const DEFAULT_DATA_ITEM_KEYS = [
  "courses",
  "payments",
  "user",
  "subscriptions",
  "payment_methods",
  "badges",
  "unlock_condition",
];

export const dataItem = function (item, response, options) {
  // attach JSON API type to attributes ("badges", "assignments", etc.)
  if (response == null) {
    response = {};
  }
  if (options == null) {
    options = { include: [] };
  }
  item.attributes.type = item.type;

  // attach associated models from included list within
  options.include.forEach(function (included) {
    let predicate, relationships;
    if (
      !response.included ||
      !item.relationships ||
      !item.relationships[included]
    ) {
      return;
    }

    if (Array.isArray(item.relationships[included].data)) {
      const related = {
        ids: item.relationships[included].data.map((i) => i.id),
        types: item.relationships[included].data.map((i) => i.type),
      };
      predicate = (item) =>
        Array.from(related.ids).includes(item.id) &&
        Array.from(related.types).includes(item.type);
      relationships = response.included.filter(predicate);
      if (relationships != null) {
        return (item.attributes[included] = relationships.map(
          (r) => r.attributes
        ));
      }
    } else {
      predicate = {
        id: item.relationships[included].data.id,
        type: item.relationships[included].data.type,
      };
      const relationship = response.included.find(predicate);
      if (relationship != null) {
        return (item.attributes[included] = relationship.attributes);
      }
    }
  });
  return item.attributes;
};

export const loadMany = function (modelArray, response, options, filter) {
  if (options == null) {
    options = { include: [] };
  }
  if (filter == null) {
    filter = () => true;
  }
  let items = response.data
    .map((item) => dataItem(item, response, options))
    .filter(filter);

  items.forEach((item) => modelArray.push(item));

  return items;
};

export const apiResponseToData = (responseJson, keysToInclude = []) => {
  if (keysToInclude.length === 0) {
    keysToInclude = DEFAULT_KEYS_TO_INCLUDE;
  }
  return loadMany(responseJson.data, responseJson, {
    include: keysToInclude,
  });
};

export const apiResponseToDataDataItem = (responseJson, keysToInclude = []) => {
  if (keysToInclude.length === 0) {
    keysToInclude = DEFAULT_DATA_ITEM_KEYS;
  }
  return dataItem(responseJson.data, responseJson, {
    include: DEFAULT_DATA_ITEM_KEYS,
  });
};
