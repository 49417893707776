import Transform from "../utilities/transformAPIResponse";
import {
  apiClient,
  requestHeader,
  apiDeserializer,
} from "@/services/GenericService.js";
import { importCourse } from "@/services/CourseService.js";
import {
  courseAdminOverviewAPIUrl,
  courseArchiveAPIUrl,
  courseCopyUrl,
  courseUnarchiveAPIUrl,
  courseDeleteAPIUrl,
  dashboardAdminNewActivityAPIUrl,
  coursePublishAPIUrl,
  courseUnpublishAPIUrl,
} from "@/urls.js";

export const coursesModule = {
  namespaced: true,
  state: {
    recordCount: 0,
    pageData: [],
    searching: false,
    offset: 0,
    limit: 10,
    searchInput: "",
    currentFilters: {
      has_paid: false,
      has_not_paid: false,
      published: false,
      unpublished: false,
      status: true,
      semester: [],
      year: [],
    },
  },
  actions: {
    formulateURLSearchParams: async ({ state, getters }, payload) => {
      let params = { status: state.currentFilters.status };

      if (
        state.currentFilters.published &&
        state.currentFilters.published != state.currentFilters.unpublished
      ) {
        let publishedFilter = { published: true };
        params = Object.assign(params, publishedFilter);
      } else if (
        state.currentFilters.unpublished &&
        state.currentFilters.published != state.currentFilters.unpublished
      ) {
        let publishedFilter = { published: false };
        params = Object.assign(params, publishedFilter);
      }

      if (
        state.currentFilters.has_paid &&
        state.currentFilters.has_paid != state.currentFilters.has_not_paid
      ) {
        let paidFilter = { has_paid: true };
        params = Object.assign(params, paidFilter);
      } else if (
        state.currentFilters.has_not_paid &&
        state.currentFilters.has_paid != state.currentFilters.has_not_paid
      ) {
        let paidFilter = { has_paid: false };
        params = Object.assign(params, paidFilter);
      }

      let paginationParams = { limit: state.limit, offset: state.offset };
      params = Object.assign(params, paginationParams);

      let yearFilters = { year: getters.getYearData };
      state.currentFilters.year.length
        ? Object.assign(params, yearFilters)
        : null;

      let semesterFilters = { semester: getters.getSemesterData };
      state.currentFilters.semester.length
        ? Object.assign(params, semesterFilters)
        : null;

      let searchFilter = { search: state.searchInput };
      state.searchInput.length ? Object.assign(params, searchFilter) : null;

      payload.url.search = new URLSearchParams(params).toString();
      return `${payload.url.href}`;
    },
    processPageContext: async ({ commit, dispatch }) => {
      try {
        const url = courseAdminOverviewAPIUrl.stringify();
        const apiURL = new URL(url, window.location.origin);
        let queryURL = await dispatch("formulateURLSearchParams", {
          url: apiURL,
        });
        const response = await fetch(queryURL);
        if (!response.ok) {
          let failMessage = "Unable to retrieve requested data.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          const data = await response.json();
          commit("setPaginationCount", data.recordCount);
          commit("setSearchStatus", false);
          commit("setSelectedPageContext", Transform.apiResponseToData(data));
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    processEventContext: ({ dispatch, rootState }, course) => {
      dispatch("resetOffset");
      if (rootState.user.admin && course.callpoint === "overview") {
        dispatch("getNewActivity");
      } else if (rootState.user.admin) {
        dispatch("processPageContext");
      } else {
        dispatch("getCourseMemberships", null, { root: true });
      }
    },
    deleteCourse: async ({ commit, dispatch }, { course }) => {
      const url = courseDeleteAPIUrl.stringify({ courseId: course.id });
      try {
        const response = await fetch(url, {
          method: "DELETE",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ id: course.id }),
        });
        if (!response.ok) {
          let failMessage = "There was an error deleting your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully deleted your course!",
            { root: true }
          );
          dispatch("processEventContext", course);
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    copyCourse: async ({ commit, dispatch }, { course }) => {
      const courseId = course.id;
      const url = courseCopyUrl.stringify();
      try {
        const { status } = await apiClient.post(url, course, {
          params: { id: courseId },
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
          },
        });
        if (status >= 200 && status < 300) {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully copied your course!",
            { root: true }
          );
          dispatch("processEventContext", course);
        } else {
          let failMessage = "There was an error copying your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
        commit("addErrorAlertMessageMoreInfo", error.response.data.errors, {
          root: true,
        });
      }
    },
    importCourse: async (
      { commit },
      { formData, selectedCourse, currentCourse }
    ) => {
      try {
        let status = await importCourse(formData);
        if (status >= 200 && status < 300) {
          commit(
            "addSuccessAlertMessage",
            `Imported ${selectedCourse} into ${currentCourse}`,
            { root: true }
          );
        }
      } catch (error) {
        let failMessage = `${error}`;
        commit("addErrorAlertMessage", failMessage, { root: true });
      }
    },
    copyCourseWithStudents: async ({ commit, dispatch }, { course }) => {
      const courseId = course.id;
      const url = courseCopyUrl.stringify();
      try {
        const { status } = await apiClient.post(url, course, {
          params: { id: courseId, copy_type: "with_students" },
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
          },
        });
        if (status >= 200 && status < 300) {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully copied your course!",
            { root: true }
          );
          dispatch("processEventContext", course);
        } else {
          let failMessage = "There was an error copying your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    archiveCourse: async ({ commit, dispatch, rootState }, courseID) => {
      try {
        const url = courseArchiveAPIUrl.stringify();
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ courseID: courseID }),
        });
        if (!response.ok) {
          let failMessage = "There was an error archiving your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully archived your course!",
            { root: true }
          );
          rootState.user.admin
            ? dispatch("processPageContext")
            : dispatch("getCourseMemberships", null, { root: true });
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    unarchiveCourse: async ({ commit, dispatch }, courseID) => {
      try {
        const url = courseUnarchiveAPIUrl.stringify();
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ courseID: courseID }),
        });
        if (!response.ok) {
          let failMessage = "There was an error unarchiving your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          dispatch("processPageContext");
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully unarchived your course!",
            { root: true }
          );
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    unpublishCourse: async ({ commit, dispatch, rootState }, courseID) => {
      try {
        const url = courseUnpublishAPIUrl.stringify();
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ courseID: courseID }),
        });
        if (!response.ok) {
          let failMessage = "There was an error unpublishing your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully unpublished your course!",
            { root: true }
          );
        }
        rootState.user.admin
          ? dispatch("processPageContext")
          : dispatch("getCourseMemberships", null, { root: true });
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    publishCourse: async ({ commit, dispatch, rootState }, courseID) => {
      try {
        const url = coursePublishAPIUrl.stringify();
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeader,
          credentials: "same-origin",
          body: JSON.stringify({ courseID: courseID }),
        });
        if (!response.ok) {
          let failMessage = "There was an error unpublishing your course.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          commit(
            "addSuccessAlertMessage",
            "You’ve successfully published your course!",
            { root: true }
          );
        }
        rootState.user.admin
          ? dispatch("processPageContext")
          : dispatch("getCourseMemberships", null, { root: true });
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    getNewActivity: async ({ commit }) => {
      try {
        const url = dashboardAdminNewActivityAPIUrl.stringify();
        const response = await fetch(url);
        if (!response.ok) {
          let failMessage = "There was an error updating the dashboard.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          const json = await response.json();
          let newInstructorsCount = json.instructors_count;
          let paidCoursesCount = json.paid_courses_count;
          let newSubscriptionsCount = json.subscriptions_count;
          const courses = Transform.apiResponseToData(json);
          commit(
            "addNewActivity",
            {
              courses,
              newSubscriptionsCount,
              newInstructorsCount,
              paidCoursesCount,
            },
            { root: true }
          );
        }
      } catch (error) {
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    resetOffset({ commit }) {
      commit("resetOffset");
    },
  },
  mutations: {
    setFilterYear(state, selectedYear) {
      state.currentFilters.year = selectedYear;
    },
    setFilterTerm(state, selectedFilter) {
      state.currentFilters.semester = selectedFilter;
    },
    updateOffset(state, newOffset) {
      state.offset = newOffset;
    },
    setShowSubscribed(state, boolean) {
      state.currentFilters.has_paid = boolean;
    },
    setShowUnsubscribed(state, boolean) {
      state.currentFilters.has_not_paid = boolean;
    },
    setShowPublished(state, boolean) {
      state.currentFilters.published = boolean;
    },
    setShowUnpublished(state, boolean) {
      state.currentFilters.unpublished = boolean;
    },
    setSelectedPageContext(state, courseData) {
      state.pageData = courseData.map(apiDeserializer);
    },
    setSearchStatus(state, value) {
      state.searching = value;
    },
    setSearchInput(state, input) {
      state.searchInput = input;
    },
    resetOffset(state) {
      state.offset = 0;
    },
    setTabStatus(state, selectedTabValue) {
      state.currentFilters.status = selectedTabValue;
    },
    setPaginationCount(state, count) {
      state.recordCount = count;
    },
  },
  getters: {
    getPageData: (state) => {
      return state.pageData;
    },
    getSemesterData: (state) => {
      return state.currentFilters.semester;
    },
    getYearData: (state) => {
      return state.currentFilters.year;
    },
    getRecordCount: (state) => {
      return state.recordCount;
    },
    getSearchStatus: (state) => {
      return state.searching;
    },
    perPageLimit: (state) => {
      return state.limit;
    },
    getSearchInput: (state) => {
      return state.searchInput;
    },
  },
};
