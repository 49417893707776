<template>
  <ckeditor
    tag-name="textarea"
    @ready="showWordCount"
    v-model="editorData"
    :config="editorConfig"
    :editor="Editor"
  />
  <div data-cke-word-count :id="id"></div>
</template>
<script setup>
import { ref, watch } from "vue";
import Editor from "ckeditor5-custom-build";
import { apiClient, formRequestHeader } from "@/services/GenericService.js";
import { ckEditorAPIURLs } from "@/urls.js";

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});
const id = `${props.context.id}-word-count`;
const editorData = ref(props.context.value || "");
watch(editorData, () => {
  props.context.node.input(editorData.value);
});
function showWordCount(editor) {
  let wordCountPlugin = editor.plugins.get("WordCount");
  let wordCountWrapper = document.getElementById(id);
  wordCountWrapper.appendChild(wordCountPlugin.wordCountContainer);
}

const editorConfig = {
  simpleUpload: {
    uploadUrl: ckEditorAPIURLs.upload,
    headers: formRequestHeader,
    withCredentials: true,
  },
  imageRemoveEvent: {
    callback: (imagesSrc) => {
      var viewImageURL = ckEditorAPIURLs.view;
      var imageKey = imagesSrc[0].replace(viewImageURL, "");
      apiClient.post(ckEditorAPIURLs.delete, { name: imageKey });
    },
  },
};
</script>
