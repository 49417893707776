import map from "map-obj";
import { snakeCase } from "change-case";

const isNotFileOrBlob = (arg) =>
  !(arg instanceof File || arg instanceof Blob || arg instanceof FileList);

export const transformPayloadToSnakeCase = (obj) => {
  return map(
    obj,
    function (key, val) {
      return [
        snakeCase(key, { prefixCharacters: "_" }),
        val,
        {
          shouldRecurse: isNotFileOrBlob(val),
        },
      ];
    },
    {
      deep: true,
    }
  );
};
