import { getQueryParams } from "@/utilities/requests.js";
import { apiClient } from "@/services/ApiClientV2.js";
import { JOBS_API_V2_URLS } from "@/urls.js";

export const getCourseJobs = async (userId, courseId, options = null) => {
  const filterParams = getQueryParams({ userId, courseId, options });
  const { data } = await apiClient.get(
    JOBS_API_V2_URLS.index.stringify({ courseId }),
    {
      params: filterParams,
    }
  );
  return data;
};
export const getJobRequest = async (jobId, userId, courseId, options = null) => {
  const filterParams = getQueryParams({ userId, courseId, options });
  const { data } = await apiClient.get(
    JOBS_API_V2_URLS.resource.stringify({
      courseId,
      jobId,
    }),
    { params: filterParams }
  );
  return data;
};
export const updateJobRequest = async (jobId, courseId, updateData) => {
  const { data } = await apiClient.put(
    JOBS_API_V2_URLS.resource.stringify({
      courseId,
      jobId,
    }),
    updateData
  );
  return data;
};
export const deleteJobRequest = async (jobId, courseId) => {
  const response = await apiClient.delete(
    JOBS_API_V2_URLS.resource.stringify({
      courseId,
      jobId,
    })
  );
  return response;
};
export const pollJob = async (jobId, courseId) => {
  const url = JOBS_API_V2_URLS.poll.stringify({ jobId, courseId });
  const { data } = await apiClient.put(url);
  return data;
};
