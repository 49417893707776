import Transform from "../utilities/transformAPIResponse";
import { apiDeserializer, requestHeader } from "@/services/GenericService.js";
import {
  getGradesSubsetUrl,
  getInProgressIdsUrl,
  getReleaseReadyIdsUrl,
  getResubmissionIdsUrl,
  getSubmissionSubsetUrl,
  getUngradedIdsUrl,
  postReleaseGradesURL,
} from "@/urls.js";

export const gradingStatusModule = {
  namespaced: true,
  state: {
    ungradedIds: [],
    displayedUngradedSubmissions: [],
    ungradedSubmissionsLength: 0,

    releaseReadyIds: [],
    displayedReleaseReadySubmissions: [],
    releaseReadySubmissionsLength: 0,

    resubmissionsIds: [],
    displayedResubmissions: [],
    resubmissionsLength: 0,

    inProgressIds: [],
    displayedInProgressSubmissions: [],
    inProgressSubmissionsLength: 0,

    loadChunkSize: 50,
  },
  actions: {
    // Reusable Actions:
    initializeGradingStatusSection: async function (
      { commit, dispatch },
      payload
    ) {
      const resp = await fetch(payload.sectionURL);
      if (!resp.ok) {
        commit("addErrorAlertMessage", payload.errorMessage, { root: true });
      }
      const idArray = await resp.json();
      commit(payload.setIdsMutation, idArray);
      commit(payload.setIdsLengthMutation, idArray.length);
      if (idArray.length > 0) {
        await dispatch("createSubsetDataChunk", {
          idReference: idArray,
          mutation: payload.mutation,
          url: payload.url,
          errorMessage: payload.errorMessage,
        });
      } else {
        commit(payload.mutation, []);
      }
    },
    createSubsetDataChunk: async function ({ dispatch, state }, payload) {
      const chunk = payload.idReference.splice(0, state.loadChunkSize);
      await dispatch("processSubsetDataChunk", {
        mutation: payload.mutation,
        url: payload.url(chunk),
      });
    },
    processSubsetDataChunk: async function ({ commit }, payload) {
      const resp = await fetch(payload.url);
      if (!resp.ok) {
        commit("addErrorAlertMessage", payload.errorMessage, { root: true });
      }
      const json = await resp.json();
      const transformedData = Transform.apiResponseToData(json);
      commit(payload.mutation, transformedData);
    },
    // Grading Status Actions:
    getUngradedSubmissions: async function ({ dispatch, state }) {
      dispatch("initializeGradingStatusSection", {
        sectionData: state.displayedUngradedSubmissions,
        sectionURL: getUngradedIdsUrl.stringify(),
        setIdsMutation: "setUngradedSubmissionsIds",
        setIdsLengthMutation: "setUngradedSubmissionsLength",
        errorMessage: "Unable to retrieve: Ungraded Submissions",
        mutation: "setUngradedSubmissions",
        url: getSubmissionSubsetUrl,
      });
    },
    loadMoreUngradedSubmissions: async function ({ dispatch, state }) {
      if (state.ungradedIds.length > 0) {
        dispatch("createSubsetDataChunk", {
          idReference: state.ungradedIds,
          mutation: "setUngradedSubmissions",
          url: getSubmissionSubsetUrl,
          errorMessage: "Unable to load more ungraded submissions.",
        });
      }
    },
    getReleaseReadySubmissions: async function ({ dispatch, state }) {
      const previouslyLoadedSubmissionCount =
        state.displayedReleaseReadySubmissions.length;
      await dispatch("initializeGradingStatusSection", {
        sectionData: state.displayedReleaseReadySubmissions,
        sectionURL: getReleaseReadyIdsUrl.stringify(),
        setIdsMutation: "setReleaseReadySubmissionsIds",
        setIdsLengthMutation: "setReleaseReadyStubmissionsLength",
        errorMessage: "Unable to retrieve: Ready for Release Submissions",
        mutation: "setReleaseReadySubmissions",
        url: getGradesSubsetUrl,
      });
      for (
        let index = state.displayedReleaseReadySubmissions.length;
        index < previouslyLoadedSubmissionCount;
        index += state.loadChunkSize
      ) {
        dispatch("loadMoreReleaseReadySubmissions");
      }
    },
    loadMoreReleaseReadySubmissions: async function ({ dispatch, state }) {
      if (state.releaseReadyIds.length > 0) {
        dispatch("createSubsetDataChunk", {
          idReference: state.releaseReadyIds,
          mutation: "setAdditionalReleaseReadySubmissions",
          url: getGradesSubsetUrl,
          errorMessage: "Unable to load more ready for release submissions.",
        });
      }
    },
    getResubmissions: async function ({ dispatch, state }) {
      dispatch("initializeGradingStatusSection", {
        sectionData: state.displayedResubmissions,
        sectionURL: getResubmissionIdsUrl.stringify(),
        setIdsMutation: "setResubmissionsIds",
        setIdsLengthMutation: "setResubmissionsLength",
        errorMessage: "Unable to retrieve: Resubmissions",
        mutation: "setResubmissions",
        url: getSubmissionSubsetUrl,
      });
    },
    loadMoreResubmissions: async function ({ dispatch, state }) {
      if (state.resubmissionsIds.length > 0) {
        dispatch("createSubsetDataChunk", {
          idReference: state.resubmissionsIds,
          mutation: "setResubmissions",
          url: getSubmissionSubsetUrl,
          errorMessage: "Unable to load more resubmissions.",
        });
      }
    },
    getInProgressSubmissions: async function ({ dispatch, state }) {
      const previouslyLoadedSubmissionCount =
        state.displayedInProgressSubmissions.length;
      await dispatch("initializeGradingStatusSection", {
        sectionData: state.displayedInProgressSubmissions,
        sectionURL: getInProgressIdsUrl.stringify(),
        setIdsMutation: "setInProgressSubmissionsIds",
        setIdsLengthMutation: "setInProgressSubmissionsLength",
        errorMessage: "Unable to retrieve: In Progress Submissions",
        mutation: "setInProgressSubmissions",
        url: getGradesSubsetUrl,
      });
      for (
        let index = state.displayedInProgressSubmissions.length;
        index < previouslyLoadedSubmissionCount;
        index += state.loadChunkSize
      ) {
        dispatch("loadMoreInProgressSubmissions");
      }
    },
    loadMoreInProgressSubmissions: async function ({ dispatch, state }) {
      if (state.inProgressIds > 0) {
        dispatch("createSubsetDataChunk", {
          idReference: state.inProgressIds,
          mutation: "setAdditionalInProgressSubmissions",
          url: getGradesSubsetUrl,
          errorMessage: "Unable to load more in progress submissions.",
        });
      }
    },
    postReleaseGrades: async function ({ commit, dispatch }, payload) {
      const url = postReleaseGradesURL.stringify();
      const resp = await fetch(url, {
        method: "PUT",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify({ grade_ids: payload.grade_ids }),
      });
      if (payload.component === "ReleaseReady") {
        if (!resp.ok) {
          let releaseReadyPostError =
            "Unable to Release Grades for Release Ready Section";
          commit("addErrorAlertMessage", releaseReadyPostError, { root: true });
        } else {
          let releaseReadyPostSuccess =
            "Successfully Released Selected Grades for Release Ready Section";
          commit("addSuccessAlertMessage", releaseReadyPostSuccess, {
            root: true,
          });
        }
        await dispatch("getReleaseReadySubmissions");
      }
      if (payload.component === "InProgress") {
        if (!resp.ok) {
          let inProgressPostError =
            "Unable to Release Grades for In Progress Section";
          commit("addErrorAlertMessage", inProgressPostError, { root: true });
        } else {
          let inProgressPostSuccess =
            "Successfully Released Selected Grades for In Progress Section";
          commit("addSuccessAlertMessage", inProgressPostSuccess, {
            root: true,
          });
        }
        await dispatch("getInProgressSubmissions");
      }
    },
    getEditGradeRedirection: async function ({ commit }, url) {
      const resp = await fetch(url, {
        method: "POST",
        headers: requestHeader,
        credentials: "same-origin",
      });
      if (resp.status === 404) {
        console.log(resp.status);
        let redirectionError = "No Grade Found";
        commit("addErrorAlertMessage", redirectionError, { root: true });
      } else if (resp.ok) {
        const json = await resp.json();
        window.location.href = json.link;
      }
    },
  },
  mutations: {
    // Ungraded Mutation Set
    setUngradedSubmissionsIds(state, idArray) {
      state.ungradedIds = idArray;
    },
    setUngradedSubmissionsLength(state, length) {
      state.ungradedSubmissionsLength = length;
    },
    setUngradedSubmissions(state, payload) {
      // Spread operation is meant for 'Load More'
      let caseConvertedSubmissions = payload.map(apiDeserializer);
      state.displayedUngradedSubmissions = [
        ...state.displayedUngradedSubmissions,
        ...caseConvertedSubmissions,
      ];
    },
    // Release-Ready Mutation Set
    setReleaseReadySubmissionsIds(state, idArray) {
      state.releaseReadyIds = idArray;
    },
    setReleaseReadyStubmissionsLength(state, length) {
      state.releaseReadySubmissionsLength = length;
    },
    setReleaseReadySubmissions(state, payload) {
      // This mutation is only called after POST requests and on initial page load
      let caseConvertedSubmissions = payload.map(apiDeserializer);
      state.displayedReleaseReadySubmissions = caseConvertedSubmissions;
    },
    setAdditionalReleaseReadySubmissions(state, payload) {
      // This Mutation is called on Load More Requests
      let caseConvertedSubmissions = payload.map(apiDeserializer);
      state.displayedReleaseReadySubmissions = [
        ...state.displayedReleaseReadySubmissions,
        ...caseConvertedSubmissions,
      ];
    },
    // Resubmissions Mutation Set
    setResubmissionsIds(state, idArray) {
      state.resubmissionsIds = idArray;
    },
    setResubmissions(state, payload) {
      let caseConvertedSubmissions = payload.map(apiDeserializer);
      state.displayedResubmissions = [
        ...state.displayedResubmissions,
        ...caseConvertedSubmissions,
      ];
    },
    setResubmissionsLength(state, payload) {
      state.resubmissionsLength = payload;
    },
    // In-Progress Mutation Set
    setInProgressSubmissionsIds(state, idArray) {
      state.inProgressIds = idArray;
    },
    setInProgressSubmissions(state, payload) {
      // This mutation is only called after POST requests and on initial page load
      let caseConvertedSubmissions = payload.map(apiDeserializer);
      state.displayedInProgressSubmissions = caseConvertedSubmissions;
    },
    setAdditionalInProgressSubmissions(state, payload) {
      // This Mutation is called on Load More Requests
      let caseConvertedSubmissions = payload.map(apiDeserializer);
      state.displayedInProgressSubmissions = [
        ...state.displayedInProgressSubmissions,
        ...caseConvertedSubmissions,
      ];
    },
    setInProgressSubmissionsLength(state, payload) {
      state.inProgressSubmissionsLength = payload;
    },
    // Checking and unchecking all mutation (sections that can release):
    setCheckedAttribute(state, payload) {
      if (payload.component === "InProgress") {
        state.displayedInProgressSubmissions = payload.studentArray;
      }
      if (payload.component === "ReleaseReady") {
        state.displayedReleaseReadySubmissions = payload.studentArray;
      }
    },
  },
  getters: {
    ungradedSubmissions: (state) => {
      return state.displayedUngradedSubmissions;
    },
    ungradedSubmissionsLength: (state) => {
      return state.ungradedSubmissionsLength;
    },
    releaseReadySubmissions: (state) => {
      return state.displayedReleaseReadySubmissions;
    },
    releaseReadySubmissionsLength: (state) => {
      return state.releaseReadySubmissionsLength;
    },
    resubmissions: (state) => {
      return state.displayedResubmissions;
    },
    resubmissionsLength: (state) => {
      return state.resubmissionsLength;
    },
    inProgressSubmissions: (state) => {
      return state.displayedInProgressSubmissions;
    },
    inProgressSubmissionsLength: (state) => {
      return state.inProgressSubmissionsLength;
    },
  },
};
