import { apiClient, apiDeserializer } from "@/services/GenericService.js";

import { courseDashboardAPIUrls } from "@/urls.js";

export const getAnalyticsPoints = async (courseId) => {
  const url = courseDashboardAPIUrls.analyticsPoints.stringify({
    courseId: courseId,
  });
  const { data } = await apiClient.get(url);
  return data;
};

export const getWeeklyStats = async (courseId) => {
  let url = courseDashboardAPIUrls.weeklyStats.stringify({
    courseId: courseId,
  });
  const { data } = await apiClient.get(url);
  return data.student_data;
};

export const getAssignmentsByStatus = async (courseId) => {
  let url = courseDashboardAPIUrls.assignmentsAll.stringify({
    courseId: courseId,
  });
  const { data } = await apiClient.get(url);
  return {
    submitted: data.submitted,
    planned: data.planned.map(deserializePredictedEarnedGrade),
    plannedCount: data.planned_count,
    graded: data.graded.map(deserializeGrade),
    gradedCount: data.graded_count,
  };
};

export const getEvents = async (courseId) => {
  const url = courseDashboardAPIUrls.timeline.stringify({ courseId: courseId });
  const { data } = await apiClient.get(url);
  return data.timeline.date;
};

export const getGradeSchemeProgress = async (courseId) => {
  let url = courseDashboardAPIUrls.gradeSchemeProgress.stringify({
    courseId: courseId,
  });
  const { data } = await apiClient.get(url);
  return {
    currentElement: apiDeserializer(data.current_element),
    firstElement: apiDeserializer(data.first_element),
    nextElement: apiDeserializer(data.next_element),
    pointsToNextLevel: data.points_to_next_level,
    previousElement: apiDeserializer(data.previous_element),
    progressPercent: data.progress_percent,
    scoreForCourse: data.score_for_course,
  };
};

export const getLearningObjectives = async (courseId) => {
  let url = courseDashboardAPIUrls.learningObjectives.stringify({
    courseId: courseId,
  });
  const { data } = await apiClient.get(url);
  return data.map(apiDeserializer);
};

export const getMessage = async (courseId) => {
  const url = courseDashboardAPIUrls.message.stringify({ courseId: courseId });
  const { data } = await apiClient.get(url);
  return data;
};

export const getWeights = async (courseId) => {
  let url = courseDashboardAPIUrls.weights.stringify({ courseId: courseId });
  const { data } = await apiClient.get(url);
  return data;
};

const deserializeGrade = ({
  id,
  feedback,
  graded_at,
  pass_fail_status,
  score,
  assignment,
}) => ({
  id,
  feedback,
  gradedAt: graded_at,
  dueAt: assignment.due_at,
  passFailStatus: pass_fail_status,
  score,
  fullPoints: assignment.full_points,
  name: assignment.name,
  url: assignment.url,
});

const deserializePredictedEarnedGrade = ({
  id,
  predicted_points,
  assignment,
}) => ({
  id,
  predictedPoints: predicted_points,
  acceptsSubmissionsUntil: assignment.accepts_submissions_until,
  dueAt: assignment.due_at,
  fullPoints: assignment.full_points,
  isPassFail: assignment.is_pass_fail,
  lateButOpen: assignment.late_but_open,
  name: assignment.name,
  url: assignment.url,
});
