import { apiResponseToDataDataItem } from "../helpers/apiRequestHelpers.js";
import {
  getCurrentCourseUrl,
  getHeaderDataUrl,
  getAnnouncementsCurrentUserUrl,
} from "@/urls.js";
export const courseModule = {
  namespaced: true,
  state: {
    currentCourse: {
      default_badge_icon: "/assets/badge.png",
      termFor: {
        default: "None found",
      },
    },
    announcementsList: null,
    unreadAnnouncementsCount: null,
    headerData: null,
    courseId: null,
  },
  getters: {
    termForAssignment(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_assignment
        : "assignment";
    },
    termForAssignmentType(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_assignment_type
        : "assignment type";
    },
    termForBadge(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_badge
        : "badge";
    },
    termForChallenge(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_challenge
        : "challenge";
    },
    termForFail(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_fail
        : "fail";
    },
    termForGradePredictor(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_grade_predictor
        : "grade predictor";
    },
    termForGroup(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_group
        : "group";
    },
    termForGSI(state) {
      return state.currentCourse.id ? state.currentCourse.term_for_gsi : "gsi";
    },
    termForLearningObjective(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_learning_objective
        : "learning objective";
    },
    termForPass(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_pass
        : "pass";
    },
    termForStudent(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_student
        : "student";
    },
    termForTeam(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_team
        : "team";
    },
    termForWeight(state) {
      return state.currentCourse.id
        ? state.currentCourse.term_for_weight
        : "weight";
    },
  },
  actions: {
    getCurrentCourse: async function ({ commit }, courseId) {
      const resp = await fetch(getCurrentCourseUrl.stringify({ courseId }));
      if (!resp.ok) {
        console.error(resp);
        throw resp;
      }
      let termFor = {};
      const json = await resp.json();
      for (var key in json.data.attributes) {
        if (key.startsWith("term_for_")) {
          termFor[key.replace("term_for_", "")] = json.data.attributes[key];
        }
      }
      const course = { ...apiResponseToDataDataItem(json), termFor: termFor };
      commit("setCurrentCourse", course);
    },
    getAnnouncements: async function ({ commit }, courseId) {
      const resp = await fetch(
        getAnnouncementsCurrentUserUrl.stringify({ courseId })
      );
      if (resp.status === 404) {
        console.log(resp.status);
      } else if (!resp.ok) {
        throw resp;
      }
      const response = await resp.json();
      const json = response.filter((x) => x.states.length == 0);
      commit("setAnnouncements", json);
      commit("setCount", json.length);
    },
    getHeaderData: async function ({ commit }, courseId) {
      const resp = await fetch(getHeaderDataUrl.stringify({ courseId }));
      if (resp.status === 404) {
        console.log(resp.status);
      } else if (!resp.ok) {
        throw resp;
      }
      const json = await resp.json();
      commit("setHeaderData", json);
    },
  },
  mutations: {
    setCurrentCourse(state, course) {
      state.currentCourse = course;
    },
    setAnnouncements(state, announcements) {
      state.announcementsList = announcements;
    },
    setHeaderData(state, data) {
      state.headerData = data;
    },
    setCount(state, num) {
      state.unreadAnnouncementsCount = num;
    },
    setCourseId(state, num) {
      state.courseId = num;
    },
  },
};
