<script setup>
import { computed } from "vue";
import { updateJobRequest } from "@/services/JobService";
import { useNotificationStore } from "@/pinia/notifications";
import {
  JOB_STATUS_TYPES,
  JOB_TYPE_DISPLAY_NAMES,
  NOTIFICATION_JOB_STATUS_MAP,
} from "@/utilities/constants.js";

const emit = defineEmits(["remove-notification"]);
const props = defineProps({
  notification: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});
const notificationStore = useNotificationStore();

const handleDismiss = () => emit("remove-notification", props.notification);

const canCancelJob = computed(() => {
  return (
    props.notification.jobType &&
    props.notification.jobStatus == JOB_STATUS_TYPES.pending
  );
});

const cancelImport = async () => {
  await updateJobRequest(props.notification.id, props.notification.courseId, {
    status: JOB_STATUS_TYPES.cancelled,
  });
  notificationStore.mutateNotification(props.notification.id, {
    status: NOTIFICATION_JOB_STATUS_MAP[JOB_STATUS_TYPES.cancelled],
    jobStatus: JOB_STATUS_TYPES.cancelled,
    message: null,
  });
};
</script>

<template>
  <div :class="`alert-box banner ${props.notification.status}`">
    <div class="alert-box__content">
      <strong class="alert-box__title">
        <span>{{ JOB_TYPE_DISPLAY_NAMES[props.notification.jobType] }}</span
        >&nbsp;<span v-html="props.notification.jobStatus"> </span>
      </strong>
      <div class="alert-box__message" aria-live="polite">
        <span v-html="props.notification.message"></span>
        <span v-if="canCancelJob"
          >&nbsp;<a @click="() => cancelImport()">Cancel Import</a></span
        >
      </div>
    </div>
    <div v-if="props.notification.dismissible">
      <button @click="handleDismiss()"><i class="p-0 fa fa-close" /></button>
    </div>
  </div>
</template>
