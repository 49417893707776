import { ref } from "vue";
import { defineStore } from "pinia";
import {
  JOB_STATUS_TYPES,
  NOTIFICATION_JOB_STATUS_MAP,
  NOTIFICATION_JOB_TASK_TYPE_MAP,
} from "@/utilities/constants.js";
import { buildJobMessage } from "@/utilities/notifications.js";
import { getCourseJobs, updateJobRequest, deleteJobRequest, pollJob } from "@/services/JobService.js";
import { useNotificationStore } from "./notifications.js";
import { NOTIFICATION_VISIBILITY_TYPES } from "../utilities/constants.js";

export const useJobStore = defineStore("jobs", () => {
  const notificationStore = useNotificationStore();
  const jobs = ref([]);
  function addJob(job) {
    jobs.value.push(job);
  }
  function removeJob(jobId) {
    jobs.value = jobs.value.filter((job) => job.id !== jobId);
  }
  function mutateJob(jobId, data) {
    jobs.value = jobs.value.map((job) => (job.id === jobId ? data : job));
  }
  function addOrUpdateJob(job) {
    if (getJobById(job.id)) {
      mutateJob(job.id, job)
    } else {
      addJob(job);
    }
  }
  function addJobNotifications() {
    jobs.value.map((job) => {
      const notificationMessage = buildJobMessage(job);
      notificationStore.addNotification({
        id: job.id,
        courseId: job.courseId,
        // Notification status - determines banner styling
        status: NOTIFICATION_JOB_STATUS_MAP[job.status],
        // Ex: background_job
        taskType: NOTIFICATION_JOB_TASK_TYPE_MAP[job.jobType],
        // Ex: canvas_grade_import
        jobType: job.jobType,
        // Status of background job
        jobStatus: job.status,
        jobData: job.params,
        message: notificationMessage,
        dismissible: true,
        visibility: NOTIFICATION_VISIBILITY_TYPES[job.notificationVisibility],
        worker: true,
      });
      if (job.status === JOB_STATUS_TYPES.pending) {
        startPoller(job.id, job.courseId);
      }
    });
  }
  function startPoller(jobId, courseId) {
    let intervalId;

    const poll = async () => {
      try {
        const { data } = await pollJob(jobId, courseId);
        const job = data;
        const jobFinished = [
          JOB_STATUS_TYPES.failed,
          JOB_STATUS_TYPES.completed,
          JOB_STATUS_TYPES.cancelled,
        ].includes(job.status);
        if (!job || jobFinished) {
          mutateJob(jobId, job);
          const currentStatus = JOB_STATUS_TYPES[job.status];
          const notificationMessage = buildJobMessage(job);
          notificationStore.mutateNotification(jobId, {
            status: NOTIFICATION_JOB_STATUS_MAP[currentStatus],
            jobStatus: job.status,
            message: notificationMessage,
            visibility:
              NOTIFICATION_VISIBILITY_TYPES[job.notificationVisibility],
          })
          clearInterval(intervalId);
        }
      } catch (e) {
        console.debug(e);
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(poll, 5000);
  }
  async function getJobs(userId, courseId, options = null) {
    try {
      const { data } = await getCourseJobs(userId, courseId, options);
      data.map((job) => addOrUpdateJob(job));
    } catch (err) {
      console.debug(err);
      throw err;
    }
  }
  async function updateJob(jobId, courseId, updateData) {
    const { data } = await updateJobRequest(jobId, courseId, updateData);
    mutateJob(jobId, data);
  }
  async function deleteJob(jobId, courseId) {
    await deleteJobRequest(jobId, courseId);
    removeJob(jobId);
  }
  function getJobById(jobId) {
    return jobs.value.find((job) => job.id === Number(jobId));
  }
  function getJobByUserId(userId) {
    return jobs.value.find((job) => job.userId === Number(userId));
  }
  function getJobByCourseId(courseId) {
    return jobs.value.find((job) => job.courseId === Number(courseId));
  }

  return {
    jobs,
    addJob,
    mutateJob,
    addJobNotifications,
    removeJob,
    getJobs,
    updateJob,
    deleteJob,
    getJobById,
    getJobByUserId,
    getJobByCourseId,
  };
});
