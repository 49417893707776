import { createStore } from "vuex";
import { assignmentModule } from "./stores/assignment.js";
import { attendanceModule } from "./stores/attendance.js";
import { badgesModule } from "./stores/badges.js";
import { courseModule } from "./stores/course.js";
import { coursesModule } from "./stores/courses.js";
import { gradeSchemeElementsModule } from "./stores/gradeSchemeElements.js";
import { gradingStatusModule } from "./stores/gradingStatus.js";
import { pointsPlannerModule } from "./stores/pointsPlanner.js";
import { navigationModule } from "./stores/navigation.js";
import { unlocksModule } from "./stores/unlocks.js";
import { usersModule } from "./stores/users.js";
import { courseDashboardModule } from "./stores/dashboard.js";
import { apiResponseToData } from "./helpers/apiRequestHelpers.js";
import {
  apiClient,
  formRequestHeader,
  requestHeader,
} from "@/services/GenericService.js";

import {
  changeGuideAPIUrl,
  ckEditorAPIURLs,
  getCurrentUserURL,
  userFlagAPIUrl,
  courseStudentsAPIUrl,
  gradebookAssignmentsListAPIUrl,
  gradebookStudentIdsAPIUrl,
  toggleCourseMembershipStatusAPIUrl,
  toggleStudentActivationStatusAPIUrl,
} from "@/urls.js";

const store = createStore({
  modules: {
    badges: badgesModule,
    course: courseModule,
    assignment: assignmentModule,
    attendance: attendanceModule,
    gradeSchemeElements: gradeSchemeElementsModule,
    gradingStatus: gradingStatusModule,
    courses: coursesModule,
    users: usersModule,
    unlocks: unlocksModule,
    pointsPlanner: pointsPlannerModule,
    navigation: navigationModule,
    courseDashboard: courseDashboardModule,
  },
  state: {
    editorConfig: {
      simpleUpload: {
        uploadUrl: ckEditorAPIURLs.upload,
        headers: formRequestHeader,
        withCredentials: true,
      },
      image: {
        insert: {
          type: "inline",
        },
      },
      imageRemoveEvent: {
        additionalElementTypes: ["imageInline"],
        callback: (imagesSrc) => {
          var viewImageURL = ckEditorAPIURLs.view;
          var imageKey = imagesSrc[0].replace(viewImageURL, "");
          apiClient.post(ckEditorAPIURLs.delete, { name: imageKey });
        },
      },
    },
    gradebookStudents: [],
    gradebookAssignments: [],
    errorAlertMessage: null,
    errorAlertMessageMoreInfo: [],
    successAlertMessage: null,
    scrollToLocation: null,
    allUsers: [],
    allCourses: [],
    allAssignments: [],
    allAssignmentTypes: [],
    allLearningObjectives: [],
    newActivity: {
      courses: {},
      newInstructorsCount: 0,
      newSubscriptionsCount: 0,
      paidCoursesCount: 0,
    },
    allInstitutions: [],
    userSubscription: {
      payment_methods: {},
    },
    currentSubscribedCourses: [],
    previouslySubscribedCourses: [],
    environmentTerms: {
      environmentName: "",
      teachingAssistant: "",
    },
    students: [],
    studentCount: 0,
    user: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      showGuide: null,
      hasSeenOnboarding: null,
      lastLogin: null,
      lastLogout: null,
      createdAt: null,
      subscription: {},
      courseMembership: [
        {
          id: null,
          name: "",
          number: "",
          role: "",
          instructor: "",
          url: "",
          gradingStatus: {
            url: "",
            ungraded: null,
            ready: null,
            resubmissions: null,
          },
          eventCount: null,
          announcementCount: null,
          assignments: [
            {
              name: "",
              dueDate: "",
              planned: null,
              submitted: null,
              graded: null,
              url: "",
            },
          ],
          term: {
            name: "",
            year: "",
            start: "",
            end: "",
          },
          subscribed: null,
          published: null,
          active: null,
        },
      ],
    },
  },
  actions: {
    getGradebookAssignments: async function ({ commit }) {
      const url = gradebookAssignmentsListAPIUrl.stringify();
      const resp = await fetch(url);
      if (resp.status === 404) {
        console.log(resp.status);
      } else if (!resp.ok) {
        throw resp;
      }
      const assignmentsJSON = await resp.json();
      const assignments = apiResponseToData(assignmentsJSON);
      commit("setGradebookAssignments", assignments);
    },
    getGradebookStudents: async function ({ commit, dispatch }) {
      // Batching is used:
      const url = gradebookStudentIdsAPIUrl.stringify();
      const resp = await fetch(url);
      const students = await resp.json();

      let studentCount = students.length;
      commit("setStudentCount", studentCount);

      let chunk = 50;
      for (let index = 0, len = students.length; index < len; index += chunk) {
        const studentIds = students.slice(index, index + chunk);
        dispatch("getGradebookStudentsSubset", { studentIds });
      }
    },
    getGradebookStudentsSubset: async function ({ commit }, { studentIds }) {
      const resp = await fetch(
        `/api/gradebook/students?student_ids=${studentIds}`
      );
      const json = await resp.json();
      const gradebookStudents = apiResponseToData(json);
      commit("setGradebookStudents", gradebookStudents);
    },
    toggleFlagControl({ commit }, student) {
      commit("toggleFlag", student);
    },
    flagStudent: async function ({ commit }, student) {
      let url = userFlagAPIUrl.stringify({ userId: student.id });
      const resp = await fetch(url, {
        method: "POST",
        headers: requestHeader,
        credentials: "same-origin",
      });
      if (!resp.ok) {
        let message = "There was an error flagging the student.";
        commit("addErrorAlertMessage", message);
      }
    },
    toggleCourseActivation: async function ({ commit }, payload) {
      const url = toggleStudentActivationStatusAPIUrl.stringify();
      const resp = await fetch(url, {
        method: "PUT",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify(payload),
      });
      if (!resp.ok) {
        let message = `The request to update ${payload.student.first_name} ${payload.student.last_name}'s activation status was unsuccessful.`;
        commit("addErrorAlertMessage", message);
      } else {
        let toggleCheck = !payload.student.activated_for_course
          ? "activated for this course"
          : "deactivated for this course";
        let message = `${payload.student.first_name} ${payload.student.last_name} has been ${toggleCheck}`;
        commit("addSuccessAlertMessage", message);
        commit("toggleActivation", payload);
      }
    },
    deleteCourseMembership: async function ({ state, commit }, payload) {
      const url = toggleCourseMembershipStatusAPIUrl.stringify();
      const resp = await fetch(url, {
        method: "DELETE",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify(payload),
      });
      if (!resp.ok) {
        let message = `There was an error removing ${payload.student.first_name} ${payload.student.last_name} from this course.`;
        commit("addErrorAlertMessage", message);
      } else {
        let message = `${payload.student.first_name} ${payload.student.last_name} was successfully removed from course.`;
        commit("addSuccessAlertMessage", message);
        commit("setMembershipStatus", payload);
        commit("setStudentCount", state.studentCount - 1);
      }
    },
    getAllStudents: async function ({ commit, dispatch }, courseId) {
      let url = courseStudentsAPIUrl.stringify({ courseId: courseId });
      const { data } = await apiClient.get(url, { params: { fetch_ids: 1 } });
      commit("setStudentCount", data["student_ids"].length);
      let chunk = 50;
      for (
        let index = 0, len = data["student_ids"].length;
        index < len;
        index += chunk
      ) {
        const studentIds = data["student_ids"].slice(index, index + chunk);
        dispatch("getStudentsSubset", { courseId, studentIds });
      }
    },
    getStudentsSubset: async function ({ commit }, { courseId, studentIds }) {
      let url = courseStudentsAPIUrl.stringify({ courseId: courseId });
      const { data } = await apiClient.get(url, {
        params: { fetch_ids: 0, student_ids: studentIds },
      });
      const students = apiResponseToData(data);
      commit("setAllStudents", students);
    },
    getCurrentUser: async function ({ commit }) {
      let url = getCurrentUserURL.stringify();
      const { data } = await apiClient.get(url);
      commit("setCurrentUser", data);
    },
    getCourseMemberships: async function ({ commit }) {
      const resp = await fetch("api/courses/top_level_dashboard");
      if (resp.status === 404) {
        console.log(resp.status);
      } else if (!resp.ok) {
        throw resp;
      }
      const json = await resp.json();
      const final = apiResponseToData(json);
      commit("addCourses", final);
    },
    getAllInstitutions: async function ({ commit }) {
      const resp = await fetch("/api/institutions");
      if (resp.status === 404) {
        console.log(resp.status);
      } else if (!resp.ok) {
        throw resp;
      }
      const json = await resp.json();
      const final = apiResponseToData(json);
      commit("addAllInstitutions", final);
    },
    getAllCourses: async function ({ commit }) {
      const resp = await fetch("/api/courses/admin_overview");
      if (resp.status === 404) {
        console.log(resp.status);
      } else if (!resp.ok) {
        throw resp;
      }
      const json = await resp.json();
      const final = apiResponseToData(json);
      commit("addAdminCourses", final);
    },
    getAllUsers: async function ({ commit }) {
      const resp = await fetch("/api/users");
      if (resp.status === 404) {
        console.log(resp.status);
      } else if (!resp.ok) {
        throw resp;
      }
      const json = await resp.json();
      const final = apiResponseToData(json);
      commit("addAllUsers", final);
    },
    getAllInstructors: async function ({ commit }) {
      const resp = await fetch("/api/users/instructors");
      if (resp.status === 404) {
        console.log(resp.status);
      } else if (!resp.ok) {
        throw resp;
      }
      const json = await resp.json();
      const final = apiResponseToData(json);
      commit("addAllInstructors", final);
    },
    getAllAssignments: async function ({ commit }) {
      const resp = await fetch("/api/assignments");
      if (resp.status === 404) {
        console.log("No assignments found");
      } else {
        const json = await resp.json();
        const final = apiResponseToData(json);
        commit("addAssignments", final);
      }
    },
    getAllAssignmentTypes: async function ({ commit }) {
      const resp = await fetch("/api/assignment_types");
      if (resp.status === 404) {
        console.log("No assignment types found");
      } else {
        const json = await resp.json();
        const final = apiResponseToData(json);
        commit("addAssignmentTypes", final);
      }
    },
    getAllLearningObjectives: async function ({ commit }) {
      const resp = await fetch("/api/learning_objectives/objectives");
      if (resp.status === 404) {
        console.log("No learning objectives found");
      } else {
        const json = await resp.json();
        const final = apiResponseToData(json);
        commit("addLearningObjectives", final);
      }
    },
    addNewCourse: async function ({ commit }, { course }) {
      const resp = await fetch("/api/courses", {
        method: "POST",
        headers: requestHeader,
        credentials: "same-origin",
        body: JSON.stringify(course),
      });
      if (!resp.ok) {
        let message = "There was an error creating your new course.";
        commit("addErrorAlertMessage", message);
      } else {
        store.dispatch("getCourseMemberships");
        let message = "You’ve successfully created a new course!";
        commit("setScrollToLocation", course.scrollToLocation);
        commit("addSuccessAlertMessage", message);
      }
    },
    seenOnboarding: async function ({ commit }) {
      await apiClient.put("/api/users/seen_onboarding");
      commit("setHasSeenOnboarding", true);
    },
    toggleGuideControl({ commit }) {
      commit("toggleGuide");
    },
    removeSuccessAlert({ commit }) {
      commit("removeSuccessAlert");
    },
    removeErrorAlert({ commit }) {
      commit("removeErrorAlert");
    },
    removeErrorAlertMessageMoreInfo({ commit }) {
      commit("removeErrorAlertMessageMoreInfo");
    },
    changeGuide: async function ({ state }) {
      const url = changeGuideAPIUrl.stringify();
      apiClient.put(url, {
        guideState: state.user.showGuide,
      });
    },
    addErrorAlertMessage({ commit }, message) {
      commit("addErrorAlertMessage", message);
    },
  },
  mutations: {
    setGradebookAssignments(state, assignments) {
      state.gradebookAssignments = [...assignments];
    },
    setGradebookStudents(state, students) {
      state.gradebookStudents = [...state.gradebookStudents, ...students];
    },
    setEnvironmentVariables(state, railsEnv) {
      if (railsEnv === "development") {
        state.environmentTerms.environmentName = "Local";
        state.environmentTerms.teachingAssistant = "teaching elves";
      }
      if (railsEnv === "staging") {
        state.environmentTerms.environmentName = "Staging";
        state.environmentTerms.teachingAssistant = "sous-chefs";
      }
      if (railsEnv === "production") {
        state.environmentTerms.environmentName = "Umich";
        state.environmentTerms.teachingAssistant = "GSIs";
      }
      if (railsEnv === "beta") {
        state.environmentTerms.environmentName = "App";
        state.environmentTerms.teachingAssistant = "teaching assistants";
      }
    },
    addCourses(state, courses) {
      state.user.courseMembership = courses.map((course) => {
        return {
          id: course.id,
          name: course.name,
          number: course.course_number,
          role: course.role,
          active: course.active,
          instructor: "",
          url: course.change_course_path,
          subscription: { ...course.subscription },
          gradingStatus: {
            url: course.grading_status_path,
            ungraded: course.ungraded,
            ready: course.ready_for_release,
            resubmissions: course.resubmissions,
          },
          eventCount: course.events_this_week,
          announcementCount: course.unread_announcements,
          assignments: { ...course.assignments },
          term: {
            name: course.semester,
            year: course.year,
            start: course.start_date,
            end: course.end_date,
          },
          subscribed: course.subscribed,
          published: course.published,
          termForAssignments: course.term_for_assignments,
          termForStudent: course.term_for_student,
          termForGSI: course.term_for_gsi,
        };
      });
      let subscribedCourses = state.user.courseMembership.filter((course) => {
        if (course.subscribed) {
          return course.subscription.user_id === state.user.id;
        }
        return false;
      });
      state.currentSubscribedCourses = subscribedCourses;
      state.previouslySubscribedCourses = subscribedCourses;
    },
    addNewActivity(
      state,
      { courses, newSubscriptionsCount, newInstructorsCount, paidCoursesCount }
    ) {
      state.newActivity.courses = courses.map((course) => {
        return {
          id: course.id,
          name: course.name,
          url: course.change_course_path,
          editURL: course.edit_course_path,
          active: course.active,
          published: course.published,
          ltiID: course.lti_uid,
          subscribed: course.subscribed,
          semester: course.semester,
          year: course.year,
          number: course.course_number,
          studentCount: course.student_count,
          created: course.formatted_created_at,
          instructors: { ...course.staff },
        };
      });
      state.newActivity.newSubscriptionsCount = newSubscriptionsCount;
      state.newActivity.newInstructorsCount = newInstructorsCount;
      state.newActivity.paidCoursesCount = paidCoursesCount;
    },
    addAdminCourses(state, courses) {
      state.allCourses = courses.map((course) => {
        return {
          id: course.id,
          name: course.name,
          number: course.course_number,
          url: course.change_course_path,
          editURL: course.edit_course_path,
          copyStudentsURL: course.copy_courses_with_students_path,
          created: course.created_at,
          subscribed: course.subscribed,
          active: course.active,
          published: course.published,
          term: course.semester,
          year: course.year,
          searchName: course.search_string,
          studentNumber: course.student_count,
          instructors: { ...course.staff },
          subscription: { ...course.subscription },
        };
      });
    },
    addAllInstitutions(state, institutions) {
      state.allInstitutions = institutions.map((institution) => {
        return {
          id: institution.id,
          name: institution.name,
          editURL: institution.edit_url,
          hasSiteLicense: institution.has_site_license,
          institutionType: institution.institution_type,
        };
      });
    },
    addAllUsers(state, users) {
      state.allUsers = users.map((user) => {
        return {
          id: user.id,
          username: user.username,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          lastLoginAt: user.last_login_at,
          createdAt: user.created_at,
          url: user.user_url,
          subscription: "trial",
          courses: user.course_memberships.map((course) => ({
            course_id: course.course_id,
            name: course.name,
            role: course.role,
            score: course.score,
            semester: course.semester,
            year: course.year,
            url: course.change_course_path,
            active: course.active,
          })),
        };
      });
    },
    addAllInstructors(state, instructors) {
      state.allInstructors = instructors.map((instructor) => {
        return {
          id: instructor.id,
          firstName: instructor.first_name,
          lastName: instructor.last_name,
          createdAt: instructor.created_at,
          email: instructor.email,
          subscribed: instructor.subscribed,
          subscriptionExpires: instructor.subscription_expires,
          paymentMethod: instructor.payment_method,
          accountType: instructor.account_type,
          activeCoursesNumber: instructor.active_courses,
          courses: instructor.course_memberships.map((course) => ({
            id: course.id,
            name: course.course_name,
            studentCount: course.student_count,
            changeCoursePath: course.change_course_path,
            published: course.published,
            subscribed: course.subscribed,
            active: course.active,
          })),
        };
      });
    },
    addUserSubscription(state, subscriptionObj) {
      state.userSubscription = subscriptionObj;
      if (subscriptionObj.stripe_connection_error) {
        state.errorAlertMessage =
          "Oops! There was an error connecting to Stripe";
      }
    },
    toggleGuide(state) {
      state.user.showGuide = !state.user.showGuide;
    },
    setCurrentUser(state, user) {
      state.user.id = user.id;
      state.user.firstName = user.first_name;
      state.user.lastName = user.last_name;
      state.user.email = user.email;
      state.user.admin = user.admin;
      state.user.showGuide = user.show_guide;
      state.user.hasSeenOnboarding = user.has_seen_onboarding;
      state.user.avatarUrl = user.avatar_file_name.url;
      state.user.role = user.role;
    },
    addUserSubscriptionInfo(state, subscriptionInfo) {
      state.user.subscription = { ...subscriptionInfo };
    },
    setScrollToLocation(state, location) {
      state.scrollToLocation = location;
    },
    addSuccessAlertMessage(state, message) {
      state.errorAlertMessage = null;
      state.successAlertMessage = message;
    },
    removeSuccessAlert(state) {
      state.successAlertMessage = null;
    },
    addErrorAlertMessage(state, message) {
      state.successAlertMessage = null;
      state.errorAlertMessage = message;
    },
    addErrorAlertMessageMoreInfo(state, moreInfo) {
      state.errorAlertMessageMoreInfo = moreInfo;
    },
    removeErrorAlert(state) {
      state.errorAlertMessage = null;
    },
    removeErrorAlertMessageMoreInfo(state) {
      state.errorAlertMessageMoreInfo = [];
    },
    addLearningObjectives(state, message) {
      state.allLearningObjectives = message;
    },
    addAssignments(state, message) {
      state.allAssignments = message;
    },
    addAssignmentTypes(state, message) {
      state.allAssignmentTypes = message;
    },
    setMembershipStatus(state, payload) {
      const studentIndex = state.students.findIndex(
        (element) => element.id == payload.student.id
      );
      let tempState = [...this.state.students];
      tempState.splice(studentIndex, 1);
      state.students = tempState;
    },
    toggleActivation(state, payload) {
      state.students = [
        ...state.students.map((existingStudent) =>
          existingStudent.id !== payload.student.id
            ? existingStudent
            : {
                ...existingStudent,
                ...{
                  activated_for_course: !payload.student.activated_for_course,
                  rank: undefined,
                },
              }
        ),
      ];
    },
    toggleFlag(state, student) {
      state.students = [
        ...state.students.map((existingStudent) =>
          existingStudent.id !== student.id
            ? existingStudent
            : { ...existingStudent, ...{ flagged: !student.flagged } }
        ),
      ];
    },
    setAllStudents(state, students) {
      state.students = [...state.students, ...students];
    },
    setStudentCount(state, studentCount) {
      state.studentCount = studentCount;
    },
    setHasSeenOnboarding(state, newValue) {
      state.user.hasSeenOnboarding = newValue;
    },
  },
  getters: {
    environmentTerms: (state) => {
      return state.environmentTerms;
    },
    user: (state) => {
      return state.user;
    },
    currentCourses: (state) => {
      return state.user.courseMembership.filter((course) => {
        return course.active;
      });
    },
    unpublishedCourses(state, getters) {
      return getters.currentCourses.filter((course) => !course.published);
    },
    publishedCourses(state, getters) {
      return getters.currentCourses.filter((course) => course.published);
    },
    archivedCourses(state) {
      return state.user.courseMembership.filter((course) => !course.active);
    },
    allArchivedCourses: (state) => {
      return state.allCourses.filter((course) => !course.active);
    },
    editorConfig: (state) => {
      return state.editorConfig;
    },
    // Sets value to true if there are students in the state, false if empty
    studentsLoaded: (state) => !!state.students.length,
    students: (state) => {
      return state.students;
    },
    studentCount: (state) => {
      return state.studentCount;
    },
    gradebookStudents: (state) => {
      return state.gradebookStudents;
    },
    gradebookAssignments: (state) => {
      return state.gradebookAssignments;
    },
    newActivity: (state) => {
      return state.newActivity;
    },
    hasSeenOnboarding: (state) => {
      return state.user.hasSeenOnboarding;
    },
    userSubscriptionInfo: (state) => {
      if (state.user.subscription) return state.user.subscription;
      return null;
    },
    userGuideStatus: (state) => {
      return state.user.showGuide;
    },
    userCourseMemberships: (state) => {
      return state.user.courseMembership;
    },
    userActiveCourseMemberships: (state) => {
      return state.user.courseMembership.filter((course) => course.active);
    },
    isAdmin: (state) => {
      return state.user.admin;
    },
    isStaff: (state) => {
      return (
        state.user.role == "staff" ||
        state.user.role == "professor" ||
        state.user.role == "gsi"
      );
    },
    isStudent: (state) => {
      return state.user.role == "student";
    },
    isObserver: (state) => {
      return state.user.role == "observer";
    },
  },
});

export default store;
