import { apiClient } from "@/services/ApiClientV2.js";
import { NOTIFICATIONS_API_V2_URLS } from "@/urls.js";

export const getNotificationRequest = async (notificationId, courseId) => {
  const { data } = await apiClient.get(
    NOTIFICATIONS_API_V2_URLS.resource.stringify({
      courseId,
      notificationId,
    })
  );
  return data;
};
export const updateNotificationRequest = async (
  notificationId,
  courseId,
  updateData
) => {
  const { data } = await apiClient.put(
    NOTIFICATIONS_API_V2_URLS.resource.stringify({
      courseId,
      notificationId,
    }),
    updateData
  );
  return data;
};
