import {
  getCourseBadges,
  reorderCourseBadges,
} from "@/services/BadgeService.js";
import { courseBadgesDetailBaseUrl } from "@/urls.js";

const badgesBase = courseBadgesDetailBaseUrl.match(location.pathname);

export const badgesModule = {
  namespaced: true,
  state: {
    badgeId:
      badgesBase && !isNaN(badgesBase.badgeId) ? badgesBase.badgeId : null,
    allBadges: [],
  },
  mutations: {
    setBadges(state, badges) {
      state.allBadges = badges;
    },
  },
  actions: {
    async getBadges({ commit }, courseId) {
      let response = await getCourseBadges(courseId);
      commit("setBadges", response.data);
    },
    async reorderBadges({ commit }, { courseId, badges }) {
      commit("setBadges", badges);
      let badgeIds = badges.map((badge) => badge.id);
      return await reorderCourseBadges(courseId, badgeIds);
    },
  },
};
