import Transform from "../utilities/transformAPIResponse";
import { adminOverviewSearchUsersUrl, getInstructorsUrl } from "@/urls.js";
import { apiDeserializer } from "@/services/GenericService.js";
export const usersModule = {
  namespaced: true,
  state: {
    searching: false,
    searchInput: "",
    instructorFilters: {
      active: false,
      has_paid: false,
      in_published_course: false,
    },
    recordCount: 0,
    searchQueryResults: [],
    instructors: [],
    offset: 0,
    limit: 10,
  },
  actions: {
    formulateURLSearchParams: async ({ state }, payload) => {
      let params = {};
      let paginationParams = { limit: state.limit, offset: state.offset };
      Object.assign(params, paginationParams);

      let searchParams = { search: state.searchInput };
      state.searchInput.length ? Object.assign(params, searchParams) : null;

      payload.url.search = new URLSearchParams(params).toString();
      return `${payload.url.href}`;
    },
    searchAllUsers: async ({ commit, dispatch }) => {
      try {
        const url = adminOverviewSearchUsersUrl.stringify();
        const apiURL = new URL(url, window.location.origin);
        let queryURL = await dispatch("formulateURLSearchParams", {
          url: apiURL,
        });
        const response = await fetch(queryURL);
        if (!response.ok) {
          let failMessage = "Unable to complete requested search.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          const data = await response.json();
          commit("setPaginationCount", data.recordCount);
          commit("setSearchStatus", false);
          commit("setSearchedUserResults", Transform.apiResponseToData(data));
        }
      } catch (error) {
        console.log(error);
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
    processPageContext: async ({ commit, dispatch }) => {
      // TODO: Assign url based on whether user is in Instructors tab or All Users tab?
      // Should this be turned into an instructor only action?
      try {
        const url = getInstructorsUrl.stringify();
        const apiURL = new URL(url, window.location.origin);
        let queryURL = await dispatch("formulateURLSearchParams", {
          url: apiURL,
        });
        const response = await fetch(queryURL);
        if (!response.ok) {
          let failMessage = "Unable to retrieve instructors.";
          commit("addErrorAlertMessage", failMessage, { root: true });
        } else {
          const data = await response.json();
          commit("setPaginationCount", data.recordCount);
          commit("setSearchStatus", false);
          commit("setInstructorResults", Transform.apiResponseToData(data));
        }
      } catch (error) {
        console.log(error);
        commit("addErrorAlertMessage", "A system error has occurred", {
          root: true,
        });
      }
    },
  },
  mutations: {
    setSearchedUserResults(state, results) {
      state.searchQueryResults = results.map(apiDeserializer);
    },
    setInstructorResults(state, results) {
      state.instructors = results.map(apiDeserializer);
    },
    setSearchInput(state, input) {
      state.searchInput = input;
    },
    setSearchStatus(state, value) {
      state.searching = value;
    },
    setPaginationCount(state, count) {
      state.recordCount = count;
    },
    updateOffset(state, value) {
      state.offset = value;
    },
    setShowInPublishedCourse(state, boolean) {
      state.instructorFilters.in_published_course = boolean;
    },
  },
  getters: {
    getSearchInput: (state) => {
      return state.searchInput;
    },
    getSearchQueryResults: (state) => {
      return state.searchQueryResults;
    },
    getInstructorPage: (state) => {
      return state.instructors;
    },
    getRecordCount: (state) => {
      return state.recordCount;
    },
    perPageLimit: (state) => {
      return state.limit;
    },
    getSearchStatus: (state) => {
      return state.searching;
    },
  },
};
