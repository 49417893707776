import {
  JOB_TYPES,
  JOB_STATUS_TYPES,
  JOB_TYPE_DISPLAY_NAMES,
  NOTIICATION_JOB_TASK_TYPE_DISPLAY,
  NOTIFICATION_JOB_TASK_TYPE_MAP,
} from "./constants.js";
import {
  courseAssignmentsDetailsBaseUrl,
  courseJobsDetailUrl,
} from "@/urls.js";

export const getJobDisplayValues = (job) => {
  const jobParams = job.params;
  const jobTypeDisplay = JOB_TYPE_DISPLAY_NAMES[job.jobType];
  const jobTaskTypeDisplay =
    NOTIICATION_JOB_TASK_TYPE_DISPLAY[
      NOTIFICATION_JOB_TASK_TYPE_MAP[job.jobType]
    ];
  return { jobParams, jobTypeDisplay, jobTaskTypeDisplay };
};

export const buildCanvasGradeImportStatusMessage = (job) => {
  const { jobParams } = getJobDisplayValues(job);
  let successUrl, failUrl;
  try {
    const queryParam = `?dismiss_banner=${job.id}`;
    successUrl = `${courseAssignmentsDetailsBaseUrl.stringify({
      courseId: job.courseId,
      assignmentId: jobParams.assignmentId,
    })}${queryParam}`;
    failUrl = `${courseJobsDetailUrl.stringify({
      courseId: job.courseId,
      jobId: job.id,
    })}${queryParam}`;
  } catch (e) {
    successUrl = failUrl = "";
    console.debug(e);
  }
  return (
    {
      [JOB_STATUS_TYPES.pending]: `Canvas grades for ${jobParams.assignmentName} are on their way! We'll notify you again when they're ready.`,
      [JOB_STATUS_TYPES.completed]: `Canvas grades for ${
        jobParams.assignmentName
      } are now available.${
        successUrl ? ` <a href="${successUrl}">View Grades</a>` : ""
      }`,
      [JOB_STATUS_TYPES.failed]: `Canvas grades for ${
        jobParams.assignmentName
      } failed to import.${
        failUrl ? ` <a href="${failUrl}">View Results</a>` : ""
      }`,
    }[job.status] || ""
  );
};

export const buildCanvasUserImportStatusMessage = (job) => {
  const { jobParams } = getJobDisplayValues(job);
  let jobUrl;
  try {
    const queryParam = `?dismiss_banner=${job.id}`;
    jobUrl = `${courseJobsDetailUrl.stringify({
      courseId: job.courseId,
      jobId: job.id,
    })}${queryParam}`;
  } catch (e) {
    jobUrl = "";
    console.debug(e);
  }
  return (
    {
      [JOB_STATUS_TYPES.pending]: `Canvas users are on their way! We'll notify you again when they're ready.`,
      [JOB_STATUS_TYPES.completed]: `Canvas users are now available. <a href="${jobUrl}">View Results</a>`,
      [JOB_STATUS_TYPES.failed]: `Canvas users failed to import. <a href="${jobUrl}">View Results</a>`,
    }[job.status] || ""
  );
};

export const buildJobMessage = (job) => {
  const messageFunc = {
    [JOB_TYPES.canvasGradeImport]: buildCanvasGradeImportStatusMessage,
    [JOB_TYPES.canvasUserImport]: buildCanvasUserImportStatusMessage,
  }[job.jobType];
  if (!messageFunc) {
    return `The ${job.jobType} you requested is ${job.status}.`;
  }
  return messageFunc(job);
};
