class Transform {
  dataItem(item, response, options) {
    // attach JSON API type to attributes ("badges", "assignments", etc.)
    if (response == null) {
      response = {};
    }
    if (options == null) {
      options = { include: [] };
    }
    item.attributes.type = item.type;

    // attach associated models from included list within
    options.include.forEach(function (included) {
      let predicate, relationships;
      if (
        !response.included ||
        !item.relationships ||
        !item.relationships[included]
      ) {
        return;
      }

      if (Array.isArray(item.relationships[included].data)) {
        const related = {
          ids: item.relationships[included].data.map((i) => i.id),
          types: item.relationships[included].data.map((i) => i.type),
        };
        predicate = (item) =>
          Array.from(related.ids).includes(item.id) &&
          Array.from(related.types).includes(item.type);
        relationships = response.included.filter(predicate);
        if (relationships != null) {
          return (item.attributes[included] = relationships.map(
            (r) => r.attributes
          ));
        }
      } else {
        predicate = {
          id: item.relationships[included].data.id,
          type: item.relationships[included].data.type,
        };
        const relationship = response.included.find(predicate);
        if (relationship != null) {
          return (item.attributes[included] = relationship.attributes);
        }
      }
    });
    return item.attributes;
  }

  loadMany(modelArray, response, options, filter) {
    if (options == null) {
      options = { include: [] };
    }
    if (filter == null) {
      filter = () => true;
    }
    let items = response.data
      .map((item) => this.dataItem(item, response, options))
      .filter(filter);
    items.forEach((item) => modelArray.push(item));
    return items;
  }

  apiResponseToData(responseJson) {
    return this.loadMany(responseJson.data, responseJson, {
      include: [
        "courses",
        "assignments",
        "course_memberships",
        "staff",
        "payments",
        "subscriptions",
        "billing_schemes",
        "submissions",
        "unlock_conditions",
        "grade_scheme_elements",
        "unlockable",
        "learning_objective",
        "assignment_types",
        "badges",
        "users",
      ],
    });
  }

  apiResponseToDataDataItem(responseJson) {
    this.dataItem(responseJson.data, responseJson, {
      include: [
        "courses",
        "payments",
        "user",
        "subscriptions",
        "payment_methods",
        "unlock_condition",
        "grade_scheme_elements",
      ],
    });
  }
}

export default new Transform();
