<template>
  <div class="d-flex center" :class="{ 'justify-center': !previewURL }">
    <div
      v-if="previewURL"
      class="icon-preview event-preview"
      @drop.prevent="handleDrop"
      @dragenter.prevent
      @dragover.prevent
    >
      <span class="xaxis-label">250px</span>
      <span class="yaxis-label">150px</span>

      <img :src="previewURL" class="file-upload__image-preview" alt="" />
      <button
        class="file-upload__remove-image-button"
        type="button"
        aria-label="Clear Upload"
        @click="handleClearImage"
      >
        <i class="fa fa-times" />
      </button>
    </div>

    <div
      v-else
      class="file-input__empty"
      :class="{ dragging: isDragging }"
      @drop.prevent="handleDrop"
      @dragenter.prevent="handleDragOver(true)"
      @dragleave.prevent="handleDragOver(false)"
      @dragover.prevent
    >
      Drop file to upload, or
      <button title="Browse Files" type="button" @click="handleChooseImage">
        Browse
      </button>
    </div>
    <form-kit v-if="purge" type="hidden" :name="purgeInputName" :value="1" />
  </div>
  <br />
  <input
    ref="fileInput"
    type="file"
    @input="handleInput"
    hidden
    :accept="context.accept"
  />
</template>
<script setup>
import { computed, ref } from "vue";
import { isEmpty, camelCase } from "lodash";

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const purge = ref(false);
const fileInput = ref();
const isDragging = ref(false);
const handleDragOver = (status) => {
  isDragging.value = status;
};
const previewURL = computed(() => {
  const value = props.context._value;
  if (value instanceof File) {
    return URL.createObjectURL(value);
  } else {
    return purge.value ? undefined : props.context.value;
  }
});

const purgeInputName = computed(() =>
  camelCase(`purge_${props.context.node.name}`)
);

const setFile = (files) => {
  if (!isEmpty(files)) {
    purge.value = false;
    props.context.node.input(files[0]);
  }
};

const handleDrop = (e) => setFile(e.dataTransfer.files);
const handleInput = (event) => setFile(event.target.files);
const handleChooseImage = () => fileInput.value.click();

const handleClearImage = () => {
  props.context.node.input(undefined);
  if (props.context.value) {
    purge.value = true;
  }
};
</script>
